<template>
    <section>
        <header-page :title="carpeta.nombre">
            <!-- <template v-if="$usuario.rol==2">
                <div class="col-xl-2 col-lg-4 px-0 d-middle ml-auto">
                    <div class="bg-red w-100 mx-2 text-white text-center br-12 py-2 px-4 cr-pointer tres-puntos" @click="EliminarCarpeta()">
                        Eliminar 
                    </div>
                </div>
                <div class="col my-1 px-0">
                    <router-link :to="{name: 'mis-cursos.editar.carpeta', params:{id_carpeta: id_carpeta}}">
                        <div class="bg-blue mx-2 text-white text-center br-12 py-2 px-4 cr-pointer tres-puntos" @click="redireccionar()">
                            Editar
                        </div>
                    </router-link>
                </div>
                <div class="col my-1 px-0">
                    <div class="bg-general mx-2 text-white text-center br-12 py-2 px-5 cr-pointer tres-puntos" @click="crearCurso">
                        Crear Curso 
                    </div>
                </div>
            </template> -->
        </header-page>
        <!-- Datos del Curso -->
        <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 215px);">
            <div class="row mx-0 px-3 justify-content-lg-center">
                <div class="col-auto px-2 mt-3">
                    <img :src="carpeta.imagen_firmada" style="max-height:100%;max-width:419px;" class="obj-cover br-12" />
                </div>
                <div class="col-xl col-lg-12 mt-3">
                    <p class="text-gris f-18">
                        {{ carpeta.descripcion }}
                    </p>
                    <div class="row mx-0 py-3">
                        <p class="col-auto px-0 f-600">
                            Cursos Disponibles 
                        </p>
                        <p class="col-auto">
                            {{ carpeta.cant_cursos }}
                        </p>
                        <div class="col px-0">
                            <hr class="border" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 j-center mt-3">
                <div class="col-auto mx-3">
                    <p class="f-600 text-light-gris mb-3">
                        Por Iniciar
                    </p>
                    <div class="f-40 f-600 text-purple br-12 d-middle-center rounded-circle indicador-iniciar">
                        {{ carpeta.cant_iniciar }}
                    </div>
                </div>
                <div class="col-auto mx-3">
                    <p class="f-600 text-light-gris mb-3">
                        En proceso
                    </p>
                    <div class="f-40 f-600 text-blue br-12 d-middle-center rounded-circle indicador-gestion">
                        {{ carpeta.cant_cursos -(carpeta.cant_iniciar + carpeta.cant_avance) }}
                    </div>
                </div>
                <div class="col-auto mx-3">
                    <p class="f-600 text-light-gris mb-3">
                        Terminados
                    </p>
                    <div class="f-40 f-600 text-green br-12 d-middle-center rounded-circle indicador-terminados">
                        {{ carpeta.cant_avance }}
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-3 mb-3 mt-4">
                <div class="col-12 mb-2 f-22 f-600 text-gris">
                    Cursos
                </div>
                <div v-for="(curso,idx) in cursos" :key="idx" class="col-12 py-3 border-bottom">
                    <div class="row mx-0">
                        <div class="col-2 px-0 d-flex j-center a-center">
                            <div v-if="curso.nuevo && curso.avance <=0" class="text-white text-center bg-purple br-3 py-1 px-1 px-3 my-2 ">
                                NUEVO
                            </div>
                            <p v-else class="f-600 text-center" :class="curso.avance <= 0 ? 'text-purple' : curso.avance >= 50 ? 'text-blue' : curso.avance >= 100 ? 'text-green' : ''">
                                {{ curso.avance }} %
                            </p>   
                        </div>    
                        <div class="col-4 f-600 text-gris f-17 d-middle">
                            <p class="tres-puntos">
                                {{ curso.nombre_curso }}
                            </p>
                        </div>
                        <div class="col-auto px-2 f-500 d-middle">
                            <el-tooltip placement="bottom" content="Temas Finalizados" effect="light">
                                <div>
                                    {{ curso.cant_temas_finalizado }}/{{ curso.cant_temas }}
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col d-middle-center">
                            <el-tooltip placement="bottom" content="Fecha de visualización" effect="light">
                                <div>
                                    {{ formatearFecha(curso.ultima_visualizacion,'D/M/Y') }}
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto">
                            <router-link :to="{name: 'mis-cursos.ver.curso', params: { id_curso: curso.id_curso } }">
                                <div class="bg-white shadow text-general px-4 cr-pointer py-2 br-5 f-600">
                                    {{ curso.estado }}
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modalEliminarCarpeta ref="EliminarCarpeta" />
        <modal-crear-curso ref="modalCrearCurso" />
    </section>
</template>

<script>
import CarpetasCursos from '~/services/cursos/cursos_lector'
export default {
    components:{
        modalEliminarCarpeta: () => import('~/pages/misCursos/partials/modalEliminarCarpeta'),
        modalCrearCurso: () => import('../partials/modalCrearCurso')
    },
    data(){
        return { 
            id_carpeta: parseInt(this.$route.params.id_carpeta),
            carpeta:{
                nombre: '',
                imagen: '',
                descripcion: '',
                cant_cursos: 0,
                cant_avance: 0,
                cant_iniciar: 0
            },
            cursos: []
        }
    },
    mounted(){
        this.listarCursosCarpeta()
    },
    methods: { 
        EliminarCarpeta(){
            this.$refs.EliminarCarpeta.toggle();
        },
        async listarCursosCarpeta(){
            try {
                const {data:{data}} = await CarpetasCursos.obtenerCursosCarpetaVer({id_carpeta:this.id_carpeta})
                this.carpeta = data.carpeta
                this.cursos = data.cursosUser
            } catch (e){
                this.error_catch(e)
            }
        },
        redireccionar(){
            
        },
        crearCurso(){
            this.$refs.modalCrearCurso.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>

.indicador-iniciar{
    border: 4px solid var(--color-purple);
    background-color: #C400D21A;
    min-height: 89px;
    min-width: 89px;
}
.indicador-gestion{
    border: 4px solid var(--color-blue);
    background-color: #E5F2FB;
    min-height: 89px;
    min-width: 89px;
}
.indicador-terminados{
    border: 4px solid var(--color-green);
    background-color: #E5FAEC;
    min-height: 89px;
    min-width: 89px;
}
</style>